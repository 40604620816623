import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LineLink from "../components/nav/LineLink"
import DemoCardSet from "../components/DemoCardSet"
import TestimonialsStyles from "../components/styles/TestimonialsStyles"
import PhotoElizabeth from "../images/display/home-elizabeth.jpg"
import PhotoGary from "../images/display/home-gary.jpg"
import PhotoRosalind from "../images/display/home-rosalind.jpg"
import * as urls from "../constants/pageUrls"
import LogoSMI from "../images/smi/logo-smi.svg"

const SolutionsGridLayoutStyles = styled.div`
  @media (min-width: ${props => props.theme.maxWidth}) {
    display: grid;
    grid-template-columns: ${props => props.theme.maxWidthOneThirdCol} 1fr;
    column-gap: ${props => props.theme.defaultGridGap};
    row-gap: ${props => props.theme.defaultSectionSpacing};
  }
`

const SectionStyles = styled.div`
  margin-bottom: ${props => props.theme.defaultSectionSpacing};
`

const CenteredCalloutStyles = styled.div`
  padding: ${props => props.theme.defaultSectionSpacingMobile};
  border-radius: ${props => props.theme.borderRad};
  box-shadow: ${props =>
    props.hasShadow ? props.theme.boxShadowBlue : "none"};
  width: 100%;
  max-width: 720px;
  margin: 0 auto;

  .intro-p {
    margin: 0 auto;
    font-weight: 500;
  }
`

const ImageRowStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.space4};

  .grid-image-frame {
    flex: 0 0 180px;
    padding: ${props => props.theme.spacing.space2};

    .logo-smi-block {
      display: block;
      height: 44px;
      background: url(${LogoSMI}) no-repeat transparent center center;
      background-size: contain;
    }
  }
`

const HistoryGridStyles = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.m}px) {
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-gap: ${props => props.theme.defaultGridGap};
  }

  .quote-block {
    padding: ${props => props.theme.defaultSpacing};
    background-color: ${props => props.theme.brand.light0};
    font-size: ${props => props.theme.fontSize.l};
    color: ${props => props.theme.brand.blue1};
    font-weight: 500;
    position: relative;
    margin-bottom: ${props => props.theme.spacing.space5};

    @media (min-width: ${props => props.theme.breakpoints.m}px) {
      margin-bottom: 0;

      &:after {
        content: "";
        display: block;
        position: absolute;
        border-color: ${props => props.theme.brand.clear};
        border-style: solid;
        border-width: 20px;
        border-top-color: ${props => props.theme.brand.light0};
        border-right-color: ${props => props.theme.brand.light0};
        top: 100%;
        right: ${props => props.theme.spacing.space5};
        z-index: 2;
      }
    }

    p {
      &:before {
        content: "\u201C";
        display: inline-block;
      }

      &:after {
        content: "\u201D";
        display: inline-block;
      }
    }

    .quote-author {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
`
const AboutGridStyles = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.m}px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-gap: ${props => props.theme.defaultGridGap};
  }
`

const HomePage = ({ data }) => (
  <Layout variant="hero">
    <SEO title="Home" />
    <SectionStyles>
      <SolutionsGridLayoutStyles className="grid-container mb-5">
        {/* Solutions */}
        <div className="solutions-block block-info">
          <h2>The PayPerks Solution</h2>
          <p className="p-body">
            PayPerks education and rewards drive real-world behavior change
            through easy-to-digest, interactive education paired with fun
            gamification and rewards. We help cardholders learn how to use their
            cards more effectively, while also offering valuable information on
            safety and security, budgeting and saving, and other relevant
            financial skills. Cardholders are rewarded through quizzes, usage
            rewards, and the chance to win small cash prizes in monthly
            drawings.
          </p>
        </div>
        <DemoCardSet />
      </SolutionsGridLayoutStyles>
      <CenteredCalloutStyles hasShadow className="t-alignC">
        <ImageRowStyles className="images-grid">
          <div className="grid-image-frame compact">
            <a
              href={urls.SMI_ONE_CARD_HOME_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../images/smi/logo-smiONE.png"
                alt="smiONE logo"
                layout="constrained"
                width={240}
                placeholder="blurred"
              />
            </a>
          </div>
          <div className="grid-image-frame">
            <a
              href={urls.SMI_CORP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="logo-block logo-smi-block"
                aria-label="SMI logo"
              />
            </a>
          </div>
        </ImageRowStyles>
        <p className="intro-p">
          PayPerks will be exclusively available on the{" "}
          <a
            href={urls.SMI_ONE_CARD_HOME_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            smiONE<sup>&trade;</sup>
          </a>{" "}
          Card platform operated by{" "}
          <a href={urls.SMI_CORP_URL} target="_blank" rel="noopener noreferrer">
            SMI
          </a>
          .
        </p>
      </CenteredCalloutStyles>
    </SectionStyles>

    {/* Impact */}
    <SectionStyles className="section testimonials-section">
      <h2>PayPerks History &amp; Impact</h2>
      <div>
        <p className="p-body">
          PayPerks programs have been used to improve the financial capability
          of millions of cardholders since 2010. Before PayPerks joined the SMI
          family in 2021, PayPerks partnered with various national banks,
          prepaid card issuers, Visa<sup>&reg;</sup> and Mastercard
          <sup>&reg;</sup>, non-profits, multiple states, and the Fiscal Service
          of the U.S. Treasury to bring education and rewards to federal and
          state benefit payment cards, payroll cards, EBT cards, and general
          purpose reloadable cards.
        </p>
      </div>
      <TestimonialsStyles className="testimonials-row mt-3 mb-3">
        {/* Elizabeth */}
        <div className="user-quote-block">
          <div className="photo-frame">
            <img
              className="fit"
              src={PhotoElizabeth}
              alt="Elizabeth, a PayPerks user"
            />
          </div>
          <div className="user-quote">
            <blockquote className="quote-text">
              <p>
                The PayPerks program online is really valuable to me. It&rsquo;s
                taught me a lot. And it really has improved my life.
              </p>
            </blockquote>
            <address className="quote-author">Elizabeth H.</address>
          </div>
        </div>
        {/* Rosalind */}
        <div className="user-quote-block">
          <div className="photo-frame">
            <img
              className="fit"
              src={PhotoRosalind}
              alt="Rosalind, a PayPerks user"
            />
          </div>
          <div className="user-quote">
            <blockquote className="quote-text">
              <p>
                PayPerks tutorials have helped me become more effective at
                managing my money and understanding where it is going.
              </p>
            </blockquote>
            <address className="quote-author">Rosalind C.</address>
          </div>
        </div>
        {/* Gary */}
        <div className="user-quote-block">
          <div className="photo-frame">
            <img className="fit" src={PhotoGary} alt="Gary, a PayPerks user" />
          </div>
          <div className="user-quote">
            <blockquote className="quote-text">
              <p>
                When I first got the card I didn&rsquo;t know exactly how it
                would work, and now I do by using the tutorials on PayPerks.
              </p>
            </blockquote>
            <address className="quote-author">Gary M.</address>
          </div>
        </div>
      </TestimonialsStyles>

      <HistoryGridStyles>
        <div>
          <div className="quote-block paybefore-quote-block">
            <p className="quote-text">
              When it comes to engaging cardholders and improving financial
              capabilities, PayPerks packs a one-two punch.
            </p>
            <address className="quote-author">Paybefore</address>
          </div>
        </div>
        <div>
          <p className="p-body">
            PayPerks was an integral part of the US Treasury&rsquo;s Direct
            Express<sup>&reg;</sup> card for 8 years. In 2018, cardholders who
            took all of the PayPerks education spent 27% more of their money at
            POS and reduced fees by 21%, ensuring more of their money was put to
            productive use. Cardholders reported an increase in overall
            satisfaction with their card and a wide range of personal benefits
            from increased confidence to accumulating significant savings
            through better budgeting and spend management.
          </p>
          <p className="p-body">
            The PayPerks solution won a number of awards, including
            Paybefore&rsquo;s &ldquo;The One to Watch&rdquo; and PYMNTS.com
            &ldquo;Best Newcomer&rdquo;, along with widespread industry
            recognition from The Federal Reserve Bank of Philadelphia, The White
            House, and the CFED.
          </p>
        </div>
      </HistoryGridStyles>
    </SectionStyles>
    <SectionStyles className="section about-section">
      <h2>About PayPerks &amp; SMI</h2>
      <AboutGridStyles>
        <div className="about-col about-payperks">
          <p className="p-body">
            <strong>The PayPerks solution</strong> was created by a team of
            entrepreneurs in New York with a desire to leverage technology,
            visual education, and sweepstakes-based incentives to create a
            win-win solution for prepaid card issuers to improve the financial
            literacy and financial capability of their cardholders. The PayPerks
            platform was acquired by SMI in 2021 &mdash; read the{" "}
            <a
              href={urls.SMI_PAYPERKS_RELEASE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              press release
            </a>
            .
          </p>
        </div>
        <div className="about-col about-smi">
          <p className="p-body">
            <strong>Systems and Methods, Inc. (SMI)</strong> builds innovative
            government disbursement solutions that make it easier for families
            to access critical funds that help improve the quality of their
            daily lives. Founded in 1971 in Carrollton, Ga., SMI currently
            operates comprehensive State Disbursement Units (SDUs) in 17 states
            and manages the reloadable smiONE<sup>&trade;</sup> Visa
            <sup>&reg;</sup> Prepaid Card for 1.9 million cardholders in 12
            states and 12 tribes, more than any other provider in the U.S. Learn
            more about{" "}
            <a
              href={urls.SMI_CORP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              SMI
            </a>
            .
          </p>
        </div>
      </AboutGridStyles>
      <CenteredCalloutStyles className="t-alignC">
        <hr className="hairline green1 mt-3 mb-3" />
        <div className="t-alignL">
          <p className="ts-l">
            <strong>
              To learn more about the{" "}
              <span className="text-unit">
                smiONE<sup>&trade;</sup> Card with PayPerks
              </span>
              , please email{" "}
              <a href="mailto:inquiries@smimail.net">inquiries@smimail.net</a>{" "}
              or call <a href="tel:+18002824646">800.282.4646</a>.
            </strong>
          </p>
        </div>
      </CenteredCalloutStyles>
    </SectionStyles>
  </Layout>
)

export default HomePage
