import React from "react"
import styled from "styled-components"

import DemoCard from "./DemoCard"
import DemoImage0 from "../images/demo/budget-save-demo-0.png"
import PeteTeaching from "../images/pete/pete-teaching.png"
import PeteMakingItRain from "../images/pete/pete-making-it-rain-bg.png"
import BillCurlTR from "../images/bill-curl-tr.png"
import BillCurlBL from "../images/bill-curl-bl.png"

const DemoCardSetStyle = styled.div`
  position: relative;

  // mobile
  display: grid;
  margin-top: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 3rem;

  @media (min-width: ${props => props.theme.maxWidth}) {
    display: block;
    margin-top: ${props => props.theme.defaultSpacing * 3};

    .demo-card {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      &.set-pos0 {
        margin-left: 0;
      }

      &.set-pos1 {
        margin-left: 205px;
        z-index: 2;
      }

      &.set-pos2 {
        left: inherit;
        right: 0;
      }
    }
  }
`

const DemoCardSet = () => {
  return (
    <DemoCardSetStyle className="solutions-block block-image demo-card-block">
      <DemoCard
        extraClasses="set-pos0"
        image={DemoImage0}
        caption="Education"
        transform="rotate(-6deg)"
      />
      <DemoCard
        extraClasses="set-pos1"
        image={PeteTeaching}
        caption="Gamification"
        transform="rotate(0)"
      >
        <span className="chances-value tr">100</span>
        <span className="chances-value bl">200</span>
      </DemoCard>
      <DemoCard
        extraClasses="set-pos2"
        image={PeteMakingItRain}
        caption="Rewards"
        transform="rotate(6deg)"
      >
        <div className="float-img tr">
          <img src={BillCurlTR} alt="" />
        </div>
        <div className="float-img bl">
          <img src={BillCurlBL} alt="" />
        </div>
      </DemoCard>
    </DemoCardSetStyle>
  )
}

export default DemoCardSet
