import styled from "styled-components"

const TestimonialsStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: ${props => props.theme.defaultGridGap};

  .user-quote-block {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    border-radius: ${props => props.theme.borderRad};
    box-shadow: ${props => props.theme.boxShadow};
    background-color: ${props => props.theme.brand.grey1};

    .photo-frame {
      flex: 0 1 84px;
      border-radius: 50%;
      overflow: hidden;
    }

    .user-quote {
      flex: 1;
      padding: 1rem;
      padding-right: 0;
      font-family: ${props => props.theme.fontFamily.sans};
      font-weight: 500;

      .quote-text {
        border: none;
        padding: 0;
        margin: 0;
        font-size: ${props => props.theme.fontSize.s};
        color: ${props => props.theme.brand.grey4};

        p:first-child {
          &:before {
            content: "\u201C";
            display: inline-block;
          }
        }

        p:last-child {
          &:after {
            content: "\u201D";
            display: inline-block;
          }
        }
      }

      .quote-author {
        font-weight: bold;
        margin-bottom: 0;
        font-family: ${props => props.theme.fontFamily.sans};
        color: ${props => props.theme.brand.dark1};
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.m}px) {
    .user-quote-block {
      .photo-frame {
        flex-basis: 108px;
      }
    }
  }
`

export default TestimonialsStyles
