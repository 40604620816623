import React from "react"
import styled from "styled-components"

const DemoCardStyle = styled.div`
  box-shadow: ${props => props.theme.boxShadow};
  background: ${props => props.theme.brand.white};
  width: 100%;
  display: flex;
  align-items: center;

  .image-frame {
    flex: 1 0 108px;
    padding: 1rem;

    @media (max-width: ${props => props.theme.breakpoints.s}px) {
      flex: 0 1 180px;
    }
  }

  .caption {
    padding: 1rem;
    padding-right: 2rem;
    font-size: ${props => props.theme.fontSize.s};
    color: ${props => props.theme.brand.dark1};
    font-family: ${props => props.theme.fontFamily.sans};
    text-align: right;
    margin-bottom: 0;

    @media (max-width: ${props => props.theme.breakpoints.s}px) {
      flex: 1 0 auto;
    }
  }

  @media (min-width: ${props => props.theme.maxWidth}) {
    display: block;
    transform: ${props => props.transform};
    max-width: 220px;

    .image-frame {
      padding-bottom: 0;
    }

    .image-frame,
    .caption {
      display: block;
    }
    .caption {
      text-align: center;
    }
  }

  .image-frame {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .chances-value {
      position: absolute;

      &.tr {
        top: 10%;
        right: 10%;
        transform: rotate(16deg);
      }

      &.bl {
        top: 60%;
        left: 10%;
        transform: rotate(-24deg);
      }
    }

    .float-img {
      position: absolute;
      max-width: 28px;

      img {
        display: block;
        width: 100%;
      }

      &.tr {
        top: 8%;
        right: 12%;
        transform: rotate(16deg);
      }

      &.bl {
        top: 64%;
        left: 10%;
        max-width: 32px;
      }

      @media (min-width: ${props => props.theme.maxWidth}) {
        max-width: 40px;

        &.bl {
          max-width: 48px;
        }
      }
    }
  }

  .chances-value {
    display: inline-block;
    text-align: center;
    width: auto;
    padding: 0 0.5rem;
    font-size: 1rem;
    background: #f4d47a;
    border: 1px solid #fbb42c;
    line-height: 1.5;
    color: ${props => props.theme.grey2};
    letter-spacing: 0.5px;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 4px;
      position: absolute;
      top: 50%;
      left: 0;
      background: ${props => props.theme.white};
      border-radius: 50%;
      margin-top: -2px;
      margin-left: -2px;
      box-shadow: 1px 0 0 0 #fbb42c;
    }

    &:after {
      left: 100%;
      box-shadow: -1px 0 0 0 #fbb42c;
    }

    @media (min-width: ${props => props.theme.maxWidth}) {
      font-size: 1.2rem;
      padding: 0 1rem;

      &:before,
      &:after {
        height: 6px;
        width: 6px;
        margin-top: -3px;
        margin-left: -3px;
      }
    }
  }
`

const DemoCard = ({ image, transform, caption, extraClasses, children }) => {
  return (
    <DemoCardStyle
      transform={transform}
      className={`demo-card ${extraClasses}`}
    >
      <div className="image-frame">
        <img src={image} alt="" />
        {children}
      </div>
      <h5 className="caption">{caption}</h5>
    </DemoCardStyle>
  )
}

export default DemoCard
